.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  min-width: 20rem;
  padding: 0px 32px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  cursor: pointer;
  background-color: var(--primary);
  border: 1px solid transparent;
  user-select: none;

  + .wrapper {
    margin-left: 8px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.9;
    color: #fff;
  }
}

.icon + .title,
.title + .icon {
  margin-left: 8px;
}

.icon {
  display: inline-block;
  width: 24px;
  text-align: center;
  transform: translateY(2px);
}

.loading {
  margin-right: 8px;
}

// Button types
.rounded {
  border-radius: 999px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
  border-color: rgba(22, 24, 35, 0.12);
}

.primary {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.outline {
  color: var(--primary);
  border-color: currentColor;
}

// Button sizes
.small {
  min-width: 88px;
  padding: 4px 16px;
}

.large {
  padding: 14px 16px;
  min-width: 140px;
}

@media (max-width: 576px) {
  .wrapper {
    width: 16.6rem;
    height: 4rem;
  }
}
