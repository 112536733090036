#header {
  padding: 0.8rem 10rem;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0;
  left: 0;
  height: var(--default-layout-header-height);
  background-color: var(--white);
  border-bottom: 1px solid #dddddd;
  z-index: 10;
  .header__right {
    width: 65%;
    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      .active {
        background: var(--primary);
        a {
          color: #fff;
        }
      }
      .not-active {
        &:hover {
          background: #4e7bd5;
          a {
            color: #fff;
          }
        }
      }
      li {
        width: 12rem;
        height: 4.8rem;
        border-radius: 5rem;
        font-size: 16px;
        font-weight: 600;
        transition: all 0.2s ease;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #1a1a1a;
          width: 100%;
          height: 100%;
        }
      }
    }
    .header-menu {
      display: none;
    }
  }
}

.header-drawer {
  top: var(--default-layout-header-height);
  .ant-drawer-body {
    padding: 16px 8px;
  }
}

@media (max-width: 1200px) {
  #header {
    padding: 0.8rem 2.4rem;
    .header__right {
      .tabs {
        display: none;
      }
      .header-menu {
        display: block;
        float: right;
      }
    }
  }
}

@media (max-width: 576px) {
  #header {
    padding: 7px 16px;
  }
}
