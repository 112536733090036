.menu-item {
  display: flex;
  padding: 14px 12px;
  border-radius: 4px;
  .menu-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--text-color);
  }
  .menu-icon {
    transform: translateY(2px);
    margin-right: 16px;
  }
}

.menu-item__active {
  .menu-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary);
  }
  background-color: var(--primary-light);
}
