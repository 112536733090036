@import "normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700;900&display=swap");

:root {
  --primary: #1f5bd0;
  --primary-light: #e3eef8;
  --black: #000000;
  --white: #ffffff;
  --text-color: #1a1a1a;
  --gray: #989898;
  --font-family: "Barlow";

  // Default layout
  --default-layout-header-height: 6.5rem;
  --default-layout-width: 1150px;
  --default-layout-horizontal-spacer: 24px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  font-size: 1.4rem;
  line-height: 1.5;
  text-rendering: optimizespeed;
  color: var(--text-color);
  overflow-y: overlay;
}

.App {
  position: relative;
  overflow: hidden;
}

// Scrollbar CSS
// html *::-webkit-scrollbar {
//   border-radius: 0;
//   width: 8px;
// }

// html *::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: rgba(0, 0, 0, 0.15);
// }

// html *::-webkit-scrollbar-track {
//   border-radius: 0;
//   background-color: rgba(0, 0, 0, 0);
// }

button,
input,
[tabindex] {
  outline: none;
  border: none;
}

a {
  color: var(--text-color);
  text-decoration: none;
  &:hover {
    color: unset;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
}

ul,
ol {
  list-style: none;
}

// Fonts emmbeded
// @font-face {
//   font-family: ProximaNova;
//   src: url("/assets/fonts/Proxima-Nova-Regular.woff2");
//   font-weight: 400;
// }

// @font-face {
//   font-family: ProximaNova;
//   src: url("/assets/fonts/ProximaNova-Semibold.woff2");
//   font-weight: 600;
// }

// @font-face {
//   font-family: ProximaNova;
//   src: url("/assets/fonts/Proxima-Nova-Bold.woff");
//   font-weight: 700;
// }

// @font-face {
//   font-family: SofiaPro;
//   src: url("/assets/fonts/sofiapro-bold.woff");
//   font-weight: 700;
// }

body {
  // Custom tippy tooltip
  .tippy-box {
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    background-color: rgba(84, 84, 84, 0.92);
  }

  .tippy-arrow {
    color: rgba(84, 84, 84, 0.92);
  }

  .tippy-content {
    padding: 8px 9px;
  }

  .tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
    top: -8px;
  }

  // Custom form antd
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-affix-wrapper {
    padding: 0 16px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
    ::placeholder {
      color: rgba(122, 122, 122);
    }
  }
  .ant-input-affix-wrapper-textarea-with-clear-btn {
    height: unset;
    textarea {
      height: 100%;
    }
  }
  .ant-select-dropdown {
    padding: 0;
    border-radius: 8px;
    .ant-select-item {
      height: 56px;
      padding: 16px;
      font-size: 16px;
      line-height: 24px;
      color: #1a1a1a;
      font-weight: 400;
    }
    .ant-select-item-option-selected {
      background: #e3eef8;
    }
  }

  // Custom divider antd
  .ant-divider {
    margin: 0 !important;
    border-top-color: #bebebe;
    &:after,
    &:before {
      border-top-color: #bebebe !important;
    }
  }
}
